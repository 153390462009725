import React from "react";
import ReactDOM from "react-dom";
import InputMask from "react-input-mask";

import "./style.scss";

import close from "./images/close.svg";

class Estimation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mask: "+79999999999",
      phone: "",
      name: "",
      modalTitle: 'Задать вопрос'
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  
  handleChange(name) {
    return (event) => {
      this.setState({ [name]: event.target.value });
    };
  }

  render() {
    return ReactDOM.createPortal(
      <div className="overlay express">
        <div className="modal">
          <div className="wrap-modal" id="modal">
            <div className="close" onClick={this.props.onClose}>
              <img src={close} alt="" />
            </div>
            <form>
              
            <div className="title">{this.state.modalTitle}</div>
              <input
                type="text"
                placeholder="ФИО"
                onChange={this.handleChange("name")}
                required
              />
              <InputMask
                mask={this.state.mask}
                type="text"
                placeholder={'+7'}
                value={this.state.phone}
                onChange={this.handleChange("phone")}
              />
              <textarea placeholder="Вопрос"></textarea>
              <button className="button yellow question">Отправить</button>
            </form>
          </div>
        </div>
      </div>,
      document.body
    );
  }
}

export default Estimation;
