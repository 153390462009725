import Cookies from "js-cookie";

const getInitialState = () => {
  const TOKEN = Cookies.get("Token") || "";
  const initilaState = {
    userInfo: {
      mail: "",
    },
    token: TOKEN,
  };
  return initilaState;
};

export default getInitialState;
