import React from "react";
import Slider from "react-slick";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";

import one from "../../sections/two/images/one.svg";
import two from "../../sections/two/images/two.svg";
import three from "../../sections/two/images/three.svg";
import four from "../../sections/two/images/four.svg";

class Sliders extends React.Component {
  render() {
    var settings = {
      responsive: [
        {
          breakpoint: 2048,
          settings: "unslick",
        },
        {
          breakpoint: 700,
          settings: {
            dots: true,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <Slider {...settings} className="wrap-block">
        <div className="block">
          <div className="text">
            <div className="title">1 минута</div>
            <p>время на подготовку отчета по оценке</p>
          </div>
          <div className="icon">
            <img src={one} alt="" />
          </div>
        </div>
        <div className="block">
          <div className="text">
            <div className="title">200 отчетов</div>
            <p>создается на сайте каждый месяц</p>
          </div>
          <div className="icon">
            <img src={two} alt="" />
          </div>
        </div>
        <div className="block">
          <div className="text">
            <div className="title">97%</div>
            <p>точность оценки недвижимости</p>
          </div>
          <div className="icon">
            <img src={three} alt="" />
          </div>
        </div>
        <div className="block">
          <div className="text">
            <div className="title">1 день</div>
            <p>срок готовности отчета для ипотеки</p>
          </div>
          <div className="icon">
            <img src={four} alt="" />
          </div>
        </div>
      </Slider>
    );
  }
}

export default Sliders;
