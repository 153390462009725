import React from "react";
import "./App.scss";
import One from "./sections/one";
import Two from "./sections/two";
import Three from "./sections/three";
import Four from "./sections/four";
import Five from "./sections/five";
import Six from "./sections/six";

class App extends React.Component {
  render() {
    return (
      <React.Fragment>
        <One />
        <Two />
        <Three />
        <Four />
        <Five />
        <Six />
      </React.Fragment>
    );
  }
}

export default App;
