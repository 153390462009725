import React from "react";
import ScrollableAnchor from "react-scrollable-anchor";

import "./style.scss";

class Five extends React.Component {
  render() {
    return (
      <ScrollableAnchor id={"price"}>
        <section className="five">
          <div className="container">
            <div className="title">Условия и цены</div>
            <div className="wrap-block">
              <div className="left-block">
                <div className="line">
                  <div>
                    <b>Документ</b>
                  </div>
                  <div>
                    <b>Стоимость</b>
                  </div>
                </div>
                <div className="line">
                  <div>Отчёт по оценке стоимости</div>
                  <div>
                    <b>150 ₽</b>
                  </div>
                </div>
                <div className="line">
                  <div>Выписка ЕГРН</div>
                  <div>
                    <b>170 ₽</b>
                  </div>
                </div>
                <div className="line">
                  <div>Отчёт по ипотеке</div>
                  <div>
                    <b>2 300 ₽</b>
                  </div>
                </div>
                <div className="line">
                  <div>Экспресс-отчёт</div>
                  <div>
                    <b>Бесплатно</b>
                  </div>
                </div>
                <div className="line">
                  <div>Приложение для фото</div>
                  <div>
                    <b>Бесплатно</b>
                  </div>
                </div>
              </div>
              <div className="right-block">
                <div className="title">Для членов Гильдии Риэлторов</div>
                <ul className="custom">
                  <li>Скидка 15% на отчеты и выписки</li>
                  <li>Комиссионные за отчет для ипотеки - 500 ₽</li>
                  <li>Вознаграждение за использование приложения - 400 ₽</li>
                </ul>
                <div className="button yellow">
                  Присоединиться к профессионалам
                </div>
              </div>
            </div>
          </div>
        </section>
      </ScrollableAnchor>
    );
  }
}

export default Five;
