import React from 'react';
import { connect } from 'react-redux';
// import ScrollableAnchor from "react-scrollable-anchor";

import logo from './images/logo.svg';
import sale from './images/sale.svg';
import Cookies from 'js-cookie';
import store from '../../redux/';
import Registration from '../../components/registration';
import Question from '../../components/question';
import './style.scss';

const TOKEN_COOKIE = 'Token';
class One extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeClass: '',
      isModalOpenRegistration: false,
      isModalOpenQuestion: false,
      link: '#',
      enterText: 'Войти',
    };

    this.onScroll = this.onScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll() {
    let activeClass = 'active';
    if (window.scrollY < 90) {
      activeClass = '';
    }

    if (this.state.activeClass !== activeClass) {
      this.setState({ activeClass });
    }
  }

  toogleModalRegistration = () => {
    this.setState((state) => ({
      isModalOpenRegistration: !state.isModalOpenRegistration,
    }));
  };
  toogleModalQuestion = () => {
    this.setState((state) => ({
      isModalOpenQuestion: !state.isModalOpenQuestion,
    }));
  };

  redirectLink = () => {
    this.setState({ link: '/calculation' });
  };
  renderButtonEstimation = () => {
    return (
      <a href="/calculation" className="button yellow">
        Экспресс-оценка
      </a>
    );
  };

  handeleExit = () => {
    Cookies.remove(TOKEN_COOKIE);
    store.dispatch({ type: 'logout' });
    window.location.reload();
  };

  onClickLogin = () => {
    const { token } = this.props;
    if (token) {
      window.open(
        `https://domprice.pro/auth/realtor-token?token=${token}`
      );
    } else {
      this.toogleModalRegistration();
    }
  };

  render() {
    return (
      <React.Fragment>
        <section className="one">
          <div className="container">
            <div className={`menu-block ${this.state.activeClass}`}>
              <div className="container">
                <div className="left-block">
                  <a href="#top" className="logo">
                    <img src={logo} alt="" />
                  </a>
                  <ul className="menu">
                    <li>
                      <a href="#about">О сервисе</a>
                    </li>
                    <li>
                      <a href="#service">Услуги</a>
                    </li>
                    <li>
                      <a href="#price">Цены</a>
                    </li>
                  </ul>
                </div>
                <div className="right-block">
                  <div className="question" onClick={this.toogleModalQuestion}>
                    Задать вопрос
                  </div>
                  <div>
                    {String(this.props.token).length > 1 ? (
                      <div className="block-button">
                        <div className="enter" onClick={this.onClickLogin}>
                          Личный кабинет
                        </div>
                        <div className="enter" onClick={this.handeleExit}>
                          Выйти
                        </div>
                      </div>
                    ) : (
                      <div
                        className="enter"
                        onClick={this.toogleModalRegistration}
                      >
                        Войти
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="bottom-block">
              <div className="title">
                Автоматизация оценки недвижимости для риэлторов
              </div>
              <p>
                DOMPRICE.PRO- инструмент для профессионалов в сфере
                недвижимости. Мгновенная оценка стоимости жилой недвижимости,
                отчеты для ипотеки и партнерская программа для риэлторов
              </p>
              <div className="button-wrap">
                {this.renderButtonEstimation()}

                <div className="button yellow transparent">
                  Партнерская программа
                </div>
              </div>
            </div>
            <div className="sale">
              <img src={sale} alt="" />
            </div>
          </div>
        </section>
        {this.state.isModalOpenRegistration && (
          <Registration
            onClose={this.toogleModalRegistration}
            onOpen={this.toogleModalRegistration}
          />
        )}
        {this.state.isModalOpenQuestion && (
          <Question
            onClose={this.toogleModalQuestion}
            onOpen={this.toogleModalQuestion}
          />
        )}
      </React.Fragment>
    );
  }
}

function mapState(state) {
  return {
    token: state.token,
  };
}

export default connect(mapState)(One);
