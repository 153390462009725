import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import getInitialState from './initialState';
import { applyMiddleware, compose, createStore } from 'redux';

const isShowLogs = () => {
  if (process.env.IS_SERVER_SIDE) return false;
  return process.env.NODE_ENV === 'development';
};

const loggerMiddleware = createLogger({
  predicate: () => isShowLogs(),
  collapsed: true,
  timestamp: false,
});

const initialState = getInitialState();

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'auth': {
      return {
        ...state,
        token: action?.response?.token || state.token,
      };
    }

    case 'logout': {
      return {
        ...state,
        token: undefined,
      };
    }

    default:
      return state;
  }
};

const enhancer = compose(applyMiddleware(thunkMiddleware, loggerMiddleware));
const store = createStore(reducer, initialState, enhancer);

export default store;
