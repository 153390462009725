import React from "react";
import ReactDOM from "react-dom";

import "./style.scss";

import close from "./images/close.svg";

class Estimation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      priceMetrs: "123111",
      classActive: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.calcSquareMeter = this.calcSquareMeter.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  handleNameChange(event) {
    this.setState({ name: event.target.value });
  }

  calcSquareMeter = () => {
    const price = this.props.price;
    const area = this.props.area;
    const square = price / area;

    return Number(square).toFixed();
  };

  stateClass = () => {
    this.setState({ classActive: true });
  };

  calcAccuracy = () => {
    const allSquare = this.calcSquareMeter();
    const region = this.props.region;

    let accuracy = "70%";
    if (region === "Нижегородская" && 40000 < allSquare && allSquare < 95000) {
      accuracy = "87%";
    }
    if (region === "Москва" && 125000 < allSquare && allSquare < 260000) {
      accuracy = "87%";
    }
    if (
      region === "Санкт-Петербург" &&
      72500 < allSquare &&
      allSquare < 160000
    ) {
      accuracy = "87%";
    }

    return accuracy;
  };
  render() {
    const accuracy = this.calcAccuracy();
    const isGreenField = accuracy === "87%" ? "green" : "";

    return ReactDOM.createPortal(
      <div className="overlay express">
        <div className="modal">
          <div className="wrap-modal" id="modal">
            <div className="close" onClick={this.props.onClose}>
              <img src={close} alt="" />
            </div>
            <div className="title">Экспресс-оценка завершена</div>
            <div className="addres">{this.props.addres}</div>
            <div className={`price ${isGreenField}`}>
              {Number(this.props.price).toLocaleString()} ₽
            </div>
            <div className="wrap-block">
              <div className={`priceMetrs ${isGreenField}`}>{this.calcSquareMeter()} за м²</div>
              <div className={`accuracy ${isGreenField}`}>{accuracy}</div>
            </div>
            <ul className="list">
              <span>Создайте отчет для более точной цены, учитывая:</span>
              <li>Расположение окон и тип ремонта</li>
              <li>Год постройки здания и его состояние</li>
              <li>Соответствие планировки и документов</li>
              <li>Развитость инфраструктуры</li>
              <li>Текущее состояние рынка недвижимости</li>
            </ul>
          </div>
        </div>
      </div>,
      document.body
    );
  }
}

export default Estimation;
