import React from "react";
import Slider from "react-slick";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";


class sliderOpportunities extends React.Component {
  render() {
    var settings = {
      responsive: [
        {
          breakpoint: 2048,
          settings: "unslick",
        },
        {
          breakpoint: 700,
          settings: {
            dots: true,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <Slider {...settings} className="wrap-block">
        <div className="block">
          <div className="name">Удобство</div>
          <ul className="custom">
            <li>Только актуальные аналоги</li>
            <li>Отчет за 1 час, вместо 5 дней</li>
            <li>Круглосуточный доступ к сервису</li>
          </ul>
        </div>
        <div className="block">
          <div className="name">Гарантии</div>
          <ul className="custom">
            <li>Учитываем требования конкретного банка</li>
            <li>Работаем с аккредитованными оценщиками</li>
            <li>Заверяем отчет электронной подписью</li>
          </ul>
        </div>
        <div className="block">
          <div className="name">Выгода</div>
          <ul className="custom">
            <li>В 1,5 раза дешевле, чем у оценщика</li>
            <li>Бонусы за регистрацию на сайте</li>
            <li>Партнерская программа для агентств</li>
          </ul>
        </div>
      </Slider>
    );
  }
}

export default sliderOpportunities;
