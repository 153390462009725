import React from "react";
import ScrollableAnchor from "react-scrollable-anchor";

import "./style.scss";

import Slider from "../../components/sliderTwo";

class One extends React.Component {
  state = {
    activeCity: "",
    firsActive: "active",
  };

  addClass(activeCityClassName) {
    return () => {
      this.setState({ firsActive: "" });
      if (activeCityClassName === this.state.activeCity) {
        this.setState({ activeCity: "" });
      } else {
        this.setState({ activeCity: activeCityClassName });
      }
    };
  }

  getClassName(additionalClass) {
    let className = "bubble-block";

    if (additionalClass) {
      className += ` ${additionalClass}`;

      if (additionalClass === this.state.activeCity) {
        className += ` active`;
      }
    }

    return className;
  }

  renderCity(name, className) {
    return (
      <div className={this.getClassName(className)}>
        <div className="bubble">{name}</div>
        <div className="circle-block" onClick={this.addClass(className)}>
          <div className="circle"></div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <ScrollableAnchor id={"about"}>
        <section className="two">
          <div className="container">
            <div className="description">
              <div className="title">DOMPRICE.PRO</div>
              <p className="mobile-none">
                Автоматизируйте рутинные процессы агентства недвижимости с
                DOMPRICE.PRO. Больше не нужно тратить много времени на сбор
                информации о квартирах с разных сайтов, алгоритмы искусственного
                интеллекта сделают эту работу за вас. Наши технологии
                проанализируют все доступные сведения об объекте оценки и его
                аналогах, учитывая нюансов: расположение дома, этаж,
                инфраструктуру, динамику спроса и еще десяток факторов, влияющих
                на конечную стоимость объекта жилой недвижимости.
              </p>
              <p className="desktop-none">
                Автоматизируйте рутинные процессы с DOMPRICE.PRO. Алгоритмы
                искусственного интеллекта проанализируют все доступные сведения
                об объекте оценки и его аналогах, гарантируя точный расчет
                рыночной цены.
              </p>
            </div>
            <Slider />
          </div>
          <div className="map-block">
            {this.renderCity("Москва", "one-bubble")}
            {this.renderCity(
              "Нижний Новгород",
              `two-bubble ${this.state.firsActive}`
            )}
            {this.renderCity("Казань", "three-bubble")}
            {this.renderCity("Уфа", "four-bubble")}
            {this.renderCity("Санкт-Петербург", "five-bubble")}
            {this.renderCity("Новосибирск", "six-bubble")}
          </div>
        </section>
      </ScrollableAnchor>
    );
  }
}

export default One;
