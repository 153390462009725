import React from 'react';
import './style.scss';

import app from './images/app.svg';
import google from './images/google.svg';
import qr from './images/qr.svg';
import logo from '../one/images/logo.svg';

class Six extends React.Component {
  render() {
    return (
      <section className="six">
        <div className="container">
          <div className="wrap">
            <div className="title">Приложение DOMPRICE.PRO </div>
            <p>
              Бесплатный инструмент для гарантированного результата. Приложение
              проконтролирует качество фотографий для отчета и подскажет
              последовательность действий
            </p>
            <div className="download-wrap">
              <div className="download-block">
                <a
                  href="https://play.google.com/store/apps/details?id=ru.vvdev.dompricepro"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={google} alt="" />
                </a>
                <a
                  href="https://apps.apple.com/ru/app/domprice-pro/id1473574056"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={app} alt="" />
                </a>
              </div>
              <div className="qr">
                <img src={qr} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="container">
            <div className="logo">
              <img src={logo} alt="" />
            </div>
            <a href="mailto:domprice@ya.ru">domprice@ya.ru</a>
          </div>
        </div>
      </section>
    );
  }
}

export default Six;
