import React from "react";
import "./style.scss";
import Slider from "../../components/sliderOpportunities";
class Four extends React.Component {
  render() {
    return (
      <section className="four">
        <div className="container">
          <div className="title">Возможности для риэлторов</div>
          <Slider />
        </div>
      </section>
     );
  }
}

export default Four;
