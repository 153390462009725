import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import InputMask from 'react-input-mask';
import store from '../../redux';

import close from './images/close.svg';
import pdf from '../../pdf/personal-data.pdf';
import './style.scss';

class Registration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isPhone: true,
      phone: '',
      code: '',
      step: 'request',
      modalTitle: 'Регистрация',
      placeholder: 'Номер телефона',
      submit: 'Зарегистрироваться',
      requestId: '',
      token: '',
      modal: false,
      mask: '+79999999999',
      success: false,
      check: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const phone = this.state.phone;
    this.setState({
      success: true,
    });
    fetch(`/api/register/send-code`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ phone }),
    })
      .then(async (response) => {
        const data = await response.json();
        Cookies.set('Phone', phone);
        this.setState({
          requestId: data.requestId,
        });
      })
      .catch(function (error) {});

    this.setState({
      isPhone: false,
      placeholder: 'Введите код из смс',
      submit: 'Подтвердить',
      step: 'confirm',
      mask: '',
    });
  };

  handleConfirm = async (e) => {
    e.preventDefault();
    const { code } = this.state;
    const requestId = this.state.requestId;
    fetch(`/api/register/confirm`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        code,
        requestId,
        role: 'USER_ROLE_REALTOR',
      }),
    })
      .then(async (response) => {
        const data = await response.json();
        const status = await response.status;
        console.log(response);

        if (status === 400) {
          this.setState({
            placeholder: data.details.code,
            phone: '',
            check: 'check',
          });
        }
        if (status === 500) {
          this.setState({
            placeholder: 'Не правильный код',
            phone: '',
            check: 'check',
          });
        }
        if (status === 200) {
          Cookies.set('Token', data.token);
          store.dispatch({ type: 'auth', response: { token: data.token } });
          this.props.onClose();
        }
      })
      .catch((error) => {});
  };

  handleChange(name) {
    return (event) => {
      this.setState({ [name]: event.target.value });
    };
  }

  renderCheckbox() {
    if (this.state.step !== 'request') return null;

    return (
      <div className="checkBox">
        <label htmlFor="policy">
          <input id="policy" type="checkbox" required />
          <span>
            Я принимаю условия{' '}
            <a href={pdf} target="_blank" rel="noopener noreferrer">
              Пользовательского соглашенияs
            </a>{' '}
            и даю согласие на обработку персональных данных
          </span>
        </label>
      </div>
    );
  }

  repeatCode = () => {
    if (this.state.success) {
      return (
        <div className="successCode" onClick={this.handleSubmit}>
          Запросить код
        </div>
      );
    }
  };

  render() {
    const { isPhone } = this.state;
    return ReactDOM.createPortal(
      <div className="overlay">
        <div className="modal">
          <form
            onSubmit={
              this.state.step === 'request'
                ? this.handleSubmit
                : this.handleConfirm
            }
          >
            <div className="close" onClick={this.props.onClose}>
              <img src={close} alt="" />
            </div>
            <div className="title">{this.state.modalTitle}</div>
            <InputMask
              mask={this.state.mask}
              type="text"
              placeholder={this.state.placeholder}
              value={isPhone ? this.state.phone : this.state.code}
              onChange={this.handleChange(isPhone ? 'phone' : 'code')}
              className={` ${this.state.check}`}
            />
            {this.renderCheckbox()}

            <div className="wrapper">
              <button className="button yellow">{this.state.submit}</button>
              {/* {this.repeatCode()} */}
            </div>
          </form>
        </div>
      </div>,
      document.body
    );
  }
}

function mapState(state) {
  return {
    token: state.token,
  };
}

export default connect(mapState)(Registration);
