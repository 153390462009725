import React from 'react';
import ScrollableAnchor from 'react-scrollable-anchor';

import './style.scss';
import Sliders from '../../components/slider';
class One extends React.Component {
  render() {
    return (
      <ScrollableAnchor id={'service'}>
        <section className="three">
          <div className="container">
            <div className="title">Наши услуги</div>
            <Sliders />

            <div className="bonus">
              <div className="title">Дарим 750 бонусов за регистрацию</div>
              <p>
                Создайте бесплатно 5 кратких отчетов или получите скидку <br />{' '}
                на отчет для ипотеки!
              </p>
              <div className="wrap-button">
                <a className="button while" href="/calculation">
                  Попробовать
                </a>
                <div></div>
                {/* <a className="download" href="#qw">
                  Скачать пример отчета
                </a> */}
              </div>
            </div>
          </div>
        </section>
      </ScrollableAnchor>
    );
  }
}

export default One;
